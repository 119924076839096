import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import SEO from "../components/seo";

const Faq = ({ data }) => {
  return (
    <FunctionPageLayout
      title="FAQ"
      headline="Najczęściej zadawane pytania na temat systemu dla agencji reklamowej i marketingowej"
    >
      <SEO
        title="FAQ CRM - najczęściej zadawane pytania"
        description="System dla agencji reklamowej i marketingowej- FAQ✅ Najczęściej zadawane pytania • Wynajem lub zakup • Bezpieczeństwo danych • Integracje • Zapytaj o bezpłatne demo!"
      />
      <p>
        <strong>Co to znaczy, że system Empireo jest dostępny w SaaS-ie?</strong>
      </p>
      <p>
        {" "}
        Oznacza to, że udostępniamy Ci system Empireo w ramach usługi wynajmu. Opłacasz abonament miesięczny lub roczny i korzystasz z systemu z użyciem przeglądarki internetowej. Nie ma potrzeby instalacji systemu. System jest mobilny i możesz go zabrać ze sobą wszędzie, gdzie chcesz.
      </p>
      <br />
      <p>
        <strong>Czy podane ceny są cenami netto?</strong>
      </p>
      <p>
       Tak, wszystkie zawarte w cenniku kwoty są kwotami netto. Należy do nich doliczyć 23% podatek VAT.
      </p>
      <br />
      <p>
        <strong>Czy system może zostać zainstalowany na moim serwerze?</strong>
      </p>
      <p>
        {" "}
        Tak, oferujemy takie rozwiązanie. Jeżeli preferujesz samodzielne utrzymanie systemu skontaktuj się z nami, aby poznać szczegóły takiej współpracy. 
      </p>
      <br />
      <p>
        <strong>Czy mogę wypróbować Empireo zanim zdecyduję się na wynajem?</strong>
      </p>
      <p>
        Jak najbardziej. Załóż całkowicie darmową wersję demo i korzystaj z wszystkich możliwości systemu. Możesz korzystać z niej przez 30 dni bezpłatnie i bez zobowiązań.
      </p>
      <br />
      <p>
        <strong>A co, jeśli moje potrzeby są niestandardowe?</strong>
      </p>
      <p>
        Klientom z wymaganiami wykraczającymi poza naszą standardową ofertę polecamy wersję Dedicated. Jeśli zdecydujesz się na tę węrsję, możemy dowolnie rozszerzyć zakres funkcjonalny Twojego systemu. Skontaktuj się z nami — wspólnie stworzymy wizję rozwiązania dla Ciebie.
      </p>
      <br />
      <p><strong>Czy istnieje możliwość zakupu systemu?</strong></p>
      <p>Tak, system jest wtedy instalowany na Twoim serwerze.</p>
      <br />
      <p>
        <strong>Czy wdrożenie jest skomplikowane?</strong>
      </p>
      <p>
        Nie. Jeżeli decydujesz się na wynajem systemu możesz wprowadzać dane bezpośrednio do wersji testowej, która będzie Twoim docelowym systemem. Korzystasz z niej za pośrednictwem przeglądarki internetowej. Nie musisz niczego instalować.
        Jeżeli preferujesz zakup systemu lub potrzebujesz integracji z systemem ERP to do rozpoczęcia pracy w domyślnej konfiguracji wystarczy instalacja systemu oraz integracji
        i szkolenie. Oczywiście na Twoje życzenie możemy poszerzyć wdrożenie o
        dodatkowe usługi (np. stworzenie indywidualnych szablonów dokumentów).
      </p>
    </FunctionPageLayout>
  );
};

export default Faq;
